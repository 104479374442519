.breadcrumb {
    padding: 0 1.7rem;
    
    ol {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 1.6rem 0;

        li,
        li a {
            color: $middle-grey;
            font-size: 1.1rem;
        }

        li:not(:last-child)::after {
            content: '\f054';
            font-family: $font-awesome;
            margin: 0 1.28rem;
        }
    }
}