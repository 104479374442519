.btn-block-wrapper {
	align-items: center;
	display: flex;
	flex-wrap: wrap;

	.btn {
		margin: 0;
	}
}

.text-center {
	.btn-block-wrapper {
		justify-content: center;
	}
}

.text-right {
	.btn-block-wrapper {
	   justify-content: flex-end;
   }
}

.btn {
    align-items: center;
    background-color: $navy;
    border: 1px solid $navy;
    color: $white;
	cursor: pointer;
    display: inline-flex;
    font-size: 1.6rem;
    font-family: $aktiv-grotesk;
    font-weight: $font-weight-bold;
    justify-content: center;
    letter-spacing: normal;
    margin: auto;
	min-height: 5.6rem;
    padding: 1.4rem 1.6rem;
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;
	transition: 0.2s all linear;
    width: auto;
    white-space: normal;

    &:focus, &:active {
        background-color: $middle-blue;
        border-color: $middle-blue;
        box-shadow: none !important;
        color: $white;
    }

    &:hover {
        background-color: $middle-blue;
        border-color: $middle-blue;
        color: $white;
        text-decoration: none;
    }

    span {
        margin-left: 8px;
    }

    &[disabled], &[disabled]:hover {
        background-color: $middle-grey;
        cursor: default;
    }
}

.btn-primary {
    background-color: $navy;
    border-color: $navy;
    color: $white;

    &:hover {
        background-color: $middle-blue;
        border-color: $middle-blue;
        color: $white;
    }
}

.btn-outline {
    background-color: transparent;
    border-color: $navy;
    color: $navy;

    &:hover {
        background-color: $navy;
        color: white;
    }
}

.btn-link {
	background-color: transparent;
	border: none;
    color: $navy;
	padding: 0.5rem 0;
	text-transform: none;

    &:hover {
		background-color: transparent;
        color: $middle-blue;
    }

	span {
		color: $light-blue;
	}
}

.theme-dark {
    .btn {
        background-color: transparent;
        border-color: white;
        color: white;

        &:focus,
        &:active,
        &:hover {
            background-color: transparent;
        }

        &.btn-primary:not(:hover),
        &.btn-outline:hover {
            background-color: white;
            color: $navy;
        }

        &.btn-link {
            & span {
                color: white;
            }

            &:hover {
                color: $light-blue;
            }
        }
    }
}