.adj-block {
	padding-left: 0 !important;
	padding-right: 0 !important;

	&-img {
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		max-height: 32.5rem;

		img {
			visibility: hidden;
		}
	}

	&:not(.split-screen) .adj-block-img {
		height: 32.5rem;
	}

	&.split-screen {
		.adj-block-img {
			max-height: none;
		}

		.adj-block-txt {
			margin: auto;
		}

		img {
			visibility: visible;
		}
	}

	&-txt {
		padding: 2.4rem 3rem;

		img {
			margin: 0 auto;
		}
	}

	&-title {
		margin-bottom: 1.6rem;
	}

	&-title-img {
		margin-bottom: 1.65rem;
	}

	&-desc {
		margin-bottom: 2.4rem;
	}

	.btn-block-wrapper {
		justify-content: center;
	}

	&.theme-dark {
		color: white;
		background-color: $navy;
	}
}

.theme-inservco {
    .adj-block.m-resp {
        margin-top: 0;
        margin-bottom: 0;
    }
}