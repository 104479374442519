.interior-page {
    &-body {
        padding: 0 1.7rem;
    }

    &-sidebar {
        display: none;
    }

    &-main {
        .interior-main-top {
            display: block;
            margin-bottom: 2.8rem;

            .interior-page {
                &-subtitle {
                    margin: 2.4rem 0;
                }

                &-top-image img {
                    width: 100%;
                }
            }
        }

        h3 {
            color: $middle-grey;
            margin-top: 4rem;
        }
    }

}

.theme-inservco {
    .interior-page {
        &-top-image img{
            display: none;
        }
    }
}
