@import 'mixins';

@font-face {
	font-display: swap;
}

* {
    font-family: $aktiv-grotesk;
    font-style: normal;
    font-stretch: normal;
}

body, .body-1 {
	font-size: 1.8rem;
	font-weight: 400;
    line-height: 1.42;
}

.body-2 {
	font-size: 1.8rem;
	font-weight: 300;
    line-height: 1.33;
}

h1, .h1 {
    font-size: clamp(3.6rem, 5vw, 6rem);
	font-weight: 700;
    line-height: 1.13;
	margin: 0;
}

h2, .h2 {
	font-size: clamp(4.2rem, 3vw, 4.8rem);
	font-weight: 700;
    line-height: 1.29;
	margin: 0;
}

h3, .h3 {
	font-size: clamp(2.6rem, 3vw, 3.6rem);
	font-weight: 700;
    line-height: 1.29;
	margin: 0;
}

h4, .h4 {
	font-size: clamp(2.2rem, 2.8vw, 3rem);
	font-weight: 700;
    line-height: 1.33;
	margin: 0;
}

h5, .h5 {
	font-size: 2.4rem;
	font-weight: 700;
    line-height: 1.17;
	margin: 0;
}

h6, .h6 {
	font-size: 1.8rem;
	font-weight: 700;
    line-height: 1.22;
	margin: 0;
}