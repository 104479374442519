label {
	font-size: 1.6rem;
	font-weight: $font-weight-bold;
	display: block;
}

input {
    background-color: white;
    border: 1px solid #88837c;
    font-size: inherit;
    padding: 1.6rem 2.2rem;
    width: 100%;

    &:focus, &:active {
        box-shadow: 0 0 0.5rem 0 rgba($dark-blue, 0.5);
        border: 1px solid $light-blue;
        outline: none;
    }

	&:disabled {
		background-color: transparent;
	}
}

select {
    background-color: white;
    border: 1px solid #88837c;
    font-size: inherit;
	max-height: 5.6rem;
	padding: 1.6rem 2.2rem;
    width: 100%;

	&:focus, &:active {
        box-shadow: 0 0 0.5rem 0 rgba($dark-blue, 0.5);
        border: 1px solid $light-blue;
        outline: none;
    }
}

.custom-textarea {
    background-color: white;
    border: 1px solid #88837c;
    font-size: inherit;
	min-height: 16.8rem;
    padding: 1.6rem 2.2rem;
	width: 100%;

	&:focus, &:active {
        box-shadow: 0 0 0.5rem 0 rgba($dark-blue, 0.5);
        border: 1px solid $light-blue;
        outline: none;
    }
}

.custom-radio-button {
	cursor: pointer;
	display: inline-block;
	font-size: 2rem;
    font-weight: normal;
	margin-bottom: 0;
	padding-left: 3rem;
	position: relative;

	&.inline {
		display: inline-block;
		margin-bottom: 0;
		margin-right: 2.0rem;
	}

	&.selected {
		border-color: $green;
	}

	input {
		cursor: pointer;
		opacity: 0.00000001;
		width: 0;

		&:checked + .custom-radio-checkmark {
			background-color: $light-blue;

			&::after {
				display: block;
			}
		}
	}

	.custom-radio-checkmark {
		&::after {
			background: white;
			border-radius: 50%;
			height: 0.7rem;
			left: 0.5rem;
			top: 0.5rem;
			width: 0.7rem;
		}
	}

	.input-error-message {
		left: 0;
		top: -2rem;
	}
}

.custom-radio-checkmark {
	background-color: white;
	border: 1px solid $light-grey;
	border-radius: 50%;
	height: 1.9rem;
	left: 0;
	position: absolute;
	top: 2px;
	width: 1.9rem;

	&::after {
		content: '';
		display: none;
		position: absolute;
	}
}

.custom-checkbox {
	display: block;
	margin-bottom: 1rem;
	position: relative;

	label {
		cursor: pointer;
		display: block;
		font-size: 2rem;
		font-weight: normal;
		margin-bottom: 0;
		padding: 0 3.5rem 0;
		position: relative;

		&::before {
			background-color: white;
			border: .1rem solid $light-grey;
			border-radius: .2rem;
			content: '';
			height: 2rem;
			left: 0;
			position: absolute;
			top: 0.3rem;
			transition: transform 0.28s ease;
			width: 2rem;
		}

		&::after {
			border-bottom: .2rem solid white;
			border-left: .2rem solid white;
			content: '';
			display: block;
			height: .5rem;
			left: .5rem;
			position: absolute;
			top: .9rem;
			transform: rotate(-45deg) scale(0);
			transition: transform ease 0.25s;
			width: 1rem;
		}
	}

	input[type='checkbox'],
	input[type='radio'] {
		left: 0;
		margin-left: -2rem;
		opacity: 0.00000001;
		position: absolute;
		width: auto;

		&:checked ~ label {
			&:before {
				background-color: $light-blue;
			}

			&:after {
				transform: rotate(-45deg) scale(1);
			}
		}
	}
}

.input-error {
	input,
	textarea,
	select {
		border: 4px solid $burgundy;
	}

	.error-msg {
		color: $burgundy;
		font-size: 1.4rem;
		font-weight: 700;
	}
}

.upload-file-container {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	height: auto;
	margin-bottom: 3.2rem;

	a {
		color: $middle-grey;
	}

	.upload-file-btn {
		background: white;
		border: solid 1px $light-grey;
		box-shadow: none;
		color: $middle-grey;
		cursor: pointer;
		display: inline-block;
		font-size: 2rem;
		font-weight: $font-weight-light;
		min-width: 30.9rem;
		padding: 1.7rem 1.6rem;
	}

	.delete-file-btn {
		cursor: pointer;
		display: none;
		margin-top: 1rem;
	}

	.upload-file-input {
		visibility: hidden;
		width: 0;
		opacity: 0.0001;
	}

	.file-name {
		margin-left: 2.2rem;
		padding: 0;
	}
}

.EPiServerForms {
	.row {
		align-items: flex-end;
	}

	.Form__Element__Caption {
		display: block !important;
	}

	.Form__Title {
		font-size: clamp(3.6rem, 5vw, 6rem);
		font-weight: 700;
		line-height: 1.13;
	}

	.Form__Description {
		margin: 2.4rem 0;
	}

	.Form__Legend {
		@extend .h5, .p-xs-v;
	}

	.custom-checkbox label::before {
		border-color: $middle-grey;
	}

	.form__required {
		@extend .required;
	}

	.ValidationFail {
		@extend .input-error;

		.Form__Element__ValidationError {
			@extend .error-msg;
		}
	}

	.Form__Status {
		.Form__Success__Message {
			background-color: inherit;
		}
	}
}
