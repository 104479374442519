.pni-form {
  .report-a-claim-form {
    .grid-center {
      .custom-checkbox {
        margin-bottom: 0;
      }

      div {
        align-self: center;
      }
    }
  }
}