.testimonial-block {
	background-color: $dark-blue;

	.st-block {
		.container {
			padding: 4.3rem 1.7rem 3rem;
		}

		.quotation-mark {
			margin: 0 auto 3.39rem;
			width: 30px;
			height: 25px;
		}

		.divider {
			margin: 3.2rem auto 0;
		}

		&-quote {
			:first-child {
				margin-top: 0;
			}

			:last-child {
				margin-bottom: 0;
			}

			&:before,
			&:after {
				content: none;
			}
		}

		&-author {
			font-size: 2rem;
		}
	}

	.testimonial-carousel {
		padding: 0 1.7rem 3rem;

		.slick-prev,
		.slick-next {
			background: white;
			border-radius: 3rem;
			box-shadow: 2px 4px 24px 0 rgba(0, 0, 0, 0.15);
			color: $navy;
			cursor: pointer;
			height: 5.8rem;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 5.8rem;
			z-index: 1000;

			&::before {
				color: $navy;
				font-family: $font-awesome;
				font-weight: $font-weight-light;
				font-size: 2.2rem;
				line-height: 1.14;
			}

			&:hover,
			&:active {
				background: white;
			}
		}

		.slick-prev {
			left: 0;
			margin-left: 5.8rem;

			&::before {
				content: '\f053';
			}
		}

		.slick-next {
			margin-right: 2.2rem;
			right: 0;

			&::before {
				content: '\f054';
			}
		}

		.slick-dots li {
			&::marker {
				color: white;
			}

			&.slick-active button {
				background: white;
			}

			button {
				border: 2px solid white;
			}
		}

		.slick-slide {
			margin: 1.55rem;
			height: auto !important;

			& > div {
				height: 100%;
			}
		}
	
		.carousel-slide {
			border: 1px solid white;
			padding: 4.83rem 3rem 7.78rem;
			display: flex !important;
			height: 100%;
			flex-flow: column;
			justify-content: space-between;
		}

		.slick-dots-container {
			width: 25.6rem;
			overflow: hidden;
			margin: auto;

			ul {
				transition: all 0.25s;
				transform: translateX(0);
				justify-content: flex-start;

				li.slick-active {
					transform: scale(1.1);
  				transform-origin: center;
				}
			}
		}
	}

	&.theme-light {
		hr.divider {
			border-color: $black;
		}

		.testimonial-carousel {
			.carousel-slide {
				border-color: $black;
			}

			.slick-dots li {
				&.slick-active button {
					background: $black;
				}

				button {
					border-color: $black
				}
			}
		}
	}

	&.theme-dark {
		color: white;
	}
}
