.icon-block-wrapper {
	&.theme-dark {
		color: white;
		background-color: $navy;

		a,
		.icon-block-title {
			color: white;
		}

		a:hover {
			color: $light-blue;
		}
	}
}

.icon-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
	padding: 2.4rem;
	width: 100%;

	&-noImg {
		border-bottom: 1px solid rgba($border-color, 0.3);

		&:last-child {
			border-bottom: none;
		}
	}

	&-image {
		margin: 0 auto 2.5rem;
	}

	&-title {
		color: $navy;
		margin: 0 auto 2.2rem;
	}

	&-desc {
		margin: 0 auto;

		:first-child {
			margin-top: 0;
		}

		:last-child {
			margin-bottom: 0;
		}
	}
}