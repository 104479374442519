.img-hover-block {
	.img-card {
		overflow: hidden;
		position: relative;
	}

	.img-hover-img-wrapper {
		.img-card-img {
			height: 310px;
		}
	}

	.img-card-content-wrapper {
		background-color: $navy;

		.img-card-content {
			@extend .theme-dark;
			color: white;

			.btn {
				color: white;
			}
		}

		.img-card-desc {
			margin-bottom: 0;
		}
	}
}