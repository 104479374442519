@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/grid";
@import '~selectric/src/selectric';

@import url("https://use.typekit.net/klm6hev.css");
@import url("https://use.typekit.net/ulg1zww.css");
@import url("https://use.typekit.net/zcf0ele.css");
@import "global-m";
@import "typography";
@import "forms-m";

//components
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/accordion";
@import '~slick-carousel/slick/slick';
@import '../Components/Header/header-m';
@import '../Components/Footer/footer-m';
@import '../Components/Header/alert-m';
@import '../Components/ButtonBlock/button-block-m';
@import '../Components/StandardCopyBlock/standard-copy-block-m';
@import '../Components/AdjacentBlock/adjacent-block-m';
@import '../Components/SingleTestimonialBlock/single-testimonial-block-m';
@import '../Components/CarouselBlock/carousel-block-m';
@import '../Components/IconBlock/icon-block-m';
@import '../Components/FullWidthCTABlock/full-width-cta-block-m';
@import '../Components/TestimonialBlock/testimonial-block-m';
@import '../Components/ImageCardBlock/image-card-block-m';
@import '../Components/ImageHoverBlock/image-hover-block-m';
@import '../Components/AccordionBlock/accordion-block-m';
@import '../Components/LinkBlock/link-block-m';
@import '../Components/InteriorPage/interior-page-m';
@import '../Components/BreadcrumbBlock/breadcrumb-block-m';
@import '../Components/FullWidthHeroBlock/full-width-hero-block-m';
@import '../Components/LandingPage/landing-page-m';
@import '../Components/Search/search-m';
@import '../Components/MakeAPayment/make-a-payment-m';
@import '../Components//FindAnAgent/find-an-agent-m';
@import '../Components//FindAShop/find-a-shop-m';
@import '../Components/ReportAClaim/report-a-claim-m';