@import "variables";
@import "mixins";

*, :before, :after {
    box-sizing: border-box;
}

// HTML Tags ============================================================
html {
    font-size: 62.5%;
    line-height: 1.5;
}

body {
	color: $dark-grey;
	font-size: 1.8rem;
    line-height: 1.67;
    margin: 0;
    min-width: 37.5rem;
    overflow-x: hidden;
    padding: 0;
	
	&.locked {
		height: 100%;
		overflow: hidden;
		position: fixed;
		width: 100%;
	}
}

img {
	display: block;
    width: auto;
    height: auto;
    max-width: 100%;
}

blockquote, figure {
    margin: 0;
}

a {
    color: $middle-blue;
    text-decoration: none;
	transition: color 0.2s linear;

    &:hover {
		color: $dark-grey;
        text-decoration: underline;
    }
}

.body-2 {
    font-weight: $font-weight-light;
    line-height: 1.67;
}

hr {
    border: none;
    border-top: 1px solid $border-color;

    &.align-left {
        margin: 3.2rem 0;
    }

    &.align-right {
        margin: 3.2rem 0 3.2rem auto;
    }
}

hr.divider {
	border-bottom: 1px solid white;
}

hr, .theme-light hr.divider {
    border: none;
    border-bottom: 1px solid $border-color;
	border-top: none;
	height: 0;
	margin: 3.2rem auto;
	max-width: 5.6rem;
	opacity: 0.4;
}

blockquote {
    background-color: $light-grey;
    padding: 3rem;
}

.required {
    color: $burgundy;
}

i, i *,
em, em * {
    font-style: italic;
}

// Layout ============================================================
main > section {
    max-width: $grid-size;
    padding: 0 1.7rem;
    margin-left: auto;
    margin-right: auto;

    &.full-width {
        max-width: $block-grid-size;
        margin-left: auto;
        margin-right: auto;
    }
}

.block > section {
    max-width: $block-grid-size;
	margin-left: auto;
	margin-right: auto;
	padding-left: 1.7rem;
	padding-right: 1.7rem;

    &.full-width {
        max-width: $block-grid-size;
		padding-left: 0;
		padding-right: 0;
    }
}

.site-wrap {
    max-width: $grid-size;
    margin-left: auto;
    margin-right: auto;
}

.content-wrap {
    max-width: $content-grid-size;
    margin-left: auto;
    margin-right: auto;

    &.full-width {
        max-width: $block-grid-size;
    }
}

.container {
    display: block;
    max-width: 100%;
    width: 100%;
}

header ul,
footer ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.grid {
    display: grid;

    &.gg-1 {
        gap: 10px;
    }

    &.gg-2 {
        gap: 20px;
    }

    &.gg-3 {
        gap: 30px;
    }
}

// Alignment ============================================================
.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

// Font helpers ============================================================
.font-weight-bold {
    font-weight: $font-weight-bold !important;
}

.font-weight-normal {
    font-weight: $font-weight-normal !important;
}

.font-weight-light {
    font-weight: $font-weight-light !important;
}

.font-style-italic {
    font-style: italic;
}

// Border colors ============================================================
.border-burgundy { 
    &.border-full {
        border-color: $burgundy !important;
    }

    &.border-sm {
        border-bottom: 1px solid $burgundy;
    }

    &.border-md {
        border-bottom: 3px solid $burgundy;
    }

    &.border-lg {
        border-bottom: 5px solid $burgundy;
    }
}

.border-navy {
    &.border-full {
        border-color: $navy !important;
    }

    &.border-sm {
        border-bottom: 1px solid $navy;
    }

    &.border-md {
        border-bottom: 3px solid $navy;
    }

    &.border-lg {
        border-bottom: 5px solid $navy;
    }
}

.border-white {
    &.border-full {
        border-color: white !important;
    }

    &.border-sm {
        border-bottom: 1px solid white;
    }

    &.border-md {
        border-bottom: 3px solid white;
    }

    &.border-lg {
        border-bottom: 5px solid white;
    }
}

// Helpers ============================================================
.show {
    display: block !important;
}

.desktop,
.d-none {
    display: none;
}

.border {
    border: 1px solid $border-color;
}

.background-left {
    background-position: left;
}

.background-right {
    background-position: right;
}

.background-top {
    background-position: top;
}

.background-bottom {
    background-position: bottom;
}

.background-center {
    background-position: center;
}

.font-secondary {
	font-family: $adobe-garamond-pro;
}

.font-weight-normal {
    font-weight: $font-weight-normal !important;
}

.font-weight-light {
    font-weight: $font-weight-light !important;
}

// Padding ============================================================
.p-0 {
    padding: 0;
}

.p-xxs {
	padding: 1.6rem;
}

.p-xs {
    padding: 2.4rem;
}

.p-sm {
    padding: 3.2rem;
}

.p-md {
    padding: 4rem;
}

.p-lg {
    padding: 4.8rem;
}

.p-xl {
	padding: 4.8rem;
}

.p-xxl {
	padding: 6.4rem;
}

.p-xxxl {
	padding: 8rem;
}

// Vertical padding ============================================================
.p-0-v {
    padding-top: 0;
    padding-bottom: 0;
}

.p-xxs-v {
	padding-bottom: 1.6rem;
	padding-top: 1.6rem;
}

.p-xs-v {
	padding-bottom: 2.4rem;
    padding-top: 2.4rem;
}

.p-xxs-v {
	padding-bottom: 1.6rem;
	padding-top: 1.6rem;
}

.p-xs-v {
	padding-bottom: 2.4rem;
    padding-top: 2.4rem;
}

.p-sm-v {
    padding-bottom: 3.2rem;
	padding-top: 3.2rem;
}

.p-md-v {
    padding-bottom: 4rem;
	padding-top: 4rem;
}

.p-lg-v {
    padding-bottom: 4.8rem;
	padding-top: 4.8rem;
}

.p-xl-v {
	padding-bottom: 4.8rem;
	padding-top: 4.8rem;
}

.p-xxl-v {
	padding-bottom: 6.4rem;
	padding-top: 6.4rem;
}

.p-xxxl-v {
	padding-bottom: 8rem;
	padding-top: 8rem;
}

// Helpers ====================================================
.m-resp {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
}

// Accessibility ====================================================
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal
}

// Background color options ============================================================
@each $bg-color, $color in $pni-colors {
	.#{"" + $bg-color}-bg {
		background-color: $color !important;
		@if $color == $white or $color == $light-grey or $color == null {
			color: $dark-grey;
		}
		@else {
			color: white;
		}
	}
}

// Text color options ============================================================
@each $txt-color, $color in $pni-colors {
	.txt-#{"" + $txt-color} {
		color: $color !important;
	}
}

// mobile text
@include breakpoint(tabletMini-d) {
    @each $txt-color, $color in $pni-colors {
        .mobile-txt-#{"" + $txt-color} {
            color: $color !important;
        }
    }
}