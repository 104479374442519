.find-agent-form {
    &.pni-form-container {
        max-width: none;
        padding: 0 1.7rem;
    }

    .grid {
        &.g-2.gg-3 {
            gap: 0;
        }

        h5 {
            margin-bottom: 1rem;
        }

        .custom-checkbox {
            label {
                padding: unset;
                padding-left: 3.5rem;
            }
        }

        .map-btn {
            margin-left: auto;
        }
    }

    .map-wrapper {
        width: 100%;

        .map-icon {
            position: relative;

            p {
                margin: 0;
            }

            &-num {
                position: absolute;
                top: 34px;
                left: 0;
                transform: translateX(-50%);
            }

            .fa-map-marker {
                color: $burgundy;
            }
        }

        .map-item-info {
            font-size: 14px;
            max-width: 250px;
        }

        .search-results {
            margin-bottom: 0;
        }

        .agent {
            border-bottom: 1px solid rgba($border-color, 0.3);
            margin-bottom: 0;
            padding: 2rem 0;
            display: flex;
            align-items: flex-start;

            &:hover,
            &.active {
                background-color: rgba($border-color, 0.15);
            }

            p {
                margin: 0;
                font-size: 1.6rem;
                line-height: 2.4rem;
            }

            &-icon {
                margin-right: 24px;

                &-wrapper {
                    cursor: pointer;
                    position: relative;

                    .agent-icon-num {
                        color: white;
                        position: absolute;
                        left: 50%;
                        top: 1rem;
                        transform: translateX(-50%);
                        font-size: 14px;
                        line-height: 1;
                    }

                    img {
                        min-width: 34px;
                        min-height: 50px;
                        width: 100%;
                    }
                }
            }

            &-info {
                color: $middle-grey;

                .agent-company {
                    margin-bottom: 6px;
                    cursor: pointer;
                }

                .agent-divider {
                    display: inline-block;
                    margin: 0 5px;
                }
            }

            &-miles {
                margin-left: auto;
                font-size: 2.4rem;
                line-height: 3.2rem;
            }
        }

        .pagination-block {
            margin-top: 30px;
        }
    }
}

.theme-inservco {
    .pni-form {
        background-color: white;
    }

    .find-agent-form {
        padding: 0 20px;

        .map-wrapper .agents {
            div:last-of-type {
                border-bottom: none;
            }
        }
    }
}