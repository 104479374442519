@import '../../Styles/variables';
@import '../../Styles/mixins';

footer .grid-flex {
	display: flex;
}

.footer-login-wrapper {
    background-color: $burgundy;
    padding: 2.4rem 1.7rem;

    .h6, a {
        display: block;
        color: white;
        margin: 0 0 0.2rem;
    }

    .footer-login:first-child {
        border-bottom: 1px solid rgba(white, 0.4);
        padding-bottom: 2.3rem;
    }

    .footer-login {
        color: white;
    }
}

.footer-nav {
	display: none;
}

.footer-social-wrapper {
	padding: 0 1.7rem;
	margin-bottom: 2.8rem;
	margin-top: 2.3rem;

	.grid {
		justify-items: center;
	}

	.footer-application {
		justify-content: center;
		order: 1;

		li {
			align-items: center;
			border-left: 1px solid $light-grey;
			display: flex;
			margin-right: 2.5rem;
			padding-left: 2.5rem;
	
			&:first-child {
				border-left: 0;
				padding-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}

	.call {
		margin: 3rem 0 0.2rem;
		order: 3;
	}

	.footer-social {
		margin-bottom: 2.5rem;

		.social {
			color: $navy;
			font-size: 2.8rem;
			margin-right: 4rem;
			order: 0;
	
			&:last-child {
				margin-right: 0;
			}
		}
	}
}

.footer-otherLinks {
	background-color: $navy;
	padding: 3.6rem 1.7rem 5.1rem;

	.grid {
		grid-auto-columns: auto;
		grid-auto-flow: column;
		grid-gap: 2.6rem;
	}

	.grid-flex {
		flex-wrap: wrap;
	}

	ul li {
		margin-bottom: 1.8rem;
	}

	a, p {
		color: white;
	}

	p {
		font-size: 1.4rem;
	}
}

.theme-inservco {
    .footer-social-wrapper {
        .footer-social {
            margin-top: 2.5rem;
            margin-bottom: 0;
        }

        .site-wrap.grid {
            grid-template-columns: 1fr;
        }

        .call {
            margin: 2rem 0 0.2rem;
            color: $middle-grey;
            text-transform: uppercase;

            a {
                color: $middle-grey;
            }
        }
    }

    .footer-otherLinks {
        padding: 3.6rem 1.7rem 3.1rem;
    }
}  


    