.fw-cta-block {
	min-height: 39rem;
	overflow: hidden;
	position: relative;

	&-img {
		display: block;
		min-height: 39rem;
		object-fit: cover;
		object-position: center;
		width: 100%;
	}

	.content-wrap {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		padding: 0 1.7rem;
	}

	&-content {
		background-color: rgba(255, 255, 255, 0.9);
		color: $dark-grey;
		max-width: 32.4rem;
		padding: 2.2rem 1.9rem 3.2rem;
		margin: auto;

		&.no-bg {
			background-color: transparent !important;
			padding: 0;
		}
	}

	&.theme-dark {
		background-color: $navy;

		.fw-cta-block-content {
			background-color: $navy;
			color: white;
		}
	}
}
