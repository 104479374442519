@import '../../Styles/variables';
@import '../../Styles/mixins';

.fixed-header .site-header {
	left: 0;
	position: fixed;
	top: 0;
}

.site-header {
	background-color: white;
	display: grid;
	grid-template-areas:
	'menu-toggle mobile-logo mobile-tools'
	'site-alert site-alert site-alert';
	grid-template-columns: auto 15.33rem auto;
	grid-template-rows: 6.5rem auto;
	overflow-x: hidden;
	width: 100%;
	z-index: 1000;

	.mobile-menu-toggle {
		align-self: center;
		grid-area: menu-toggle;
		line-height: 1;
		margin: 0;
		padding: 0 0 0 1.7rem;

		.itm-nav-toggle {
			color: $dark-grey;
			cursor: pointer;
			display: inline-block;
			font-size: 1.8rem;
		}
	}

	.mobile-header-logo {
		align-self: center;
		display: block;
		grid-area: mobile-logo;
		max-width: 15.33rem;
	}

	.mobile-tools {
		align-self: center;
		grid-area: mobile-tools;
		justify-self: end;
		padding: 0 1.7rem 0 0;

		.mobile-user {
			color: $dark-grey;
			font-size: 1.4rem;
			font-weight: 700;
		}

		span {
			margin-right: 0.8rem;
		}
	}

	.site-wrap {
		width: 100%;
	}
}

.navigation-wrapper {
	background-color: white;
	display: flex;
	flex-direction: column;
	grid-area: nav-wrapper;
	height: 100%;
	left: 0;
	min-height: calc(100vh - 13rem);
    overflow-y: auto;
	position: absolute;
	top: 6.5rem;
	transform: translateX(-100%);
	transition: transform 0.25s linear;
	width: 100%;
	z-index: 1000;

	&.open-nav {
		transform: translateX(0);
	}
}

.secondary-nav {
	background-color: $burgundy;
	grid-area: secondary-nav;
	height: 100%;
	order: 1;
	padding: 3.9rem 1.7rem 4rem;

	.site-wrap.grid {
		grid-auto-flow: row;

		.nav-left {
			order: 1;
		}

		.nav-right {
			order: 0;
		}
	}

	ul li {
		margin-bottom: 2.4rem;

		&.nav-signin {
			display: none;
		}
	}

	ul li a {
		color: white;
		font-size: 1.4rem;
	}

	.nav-left ul li:last-child {
		margin-bottom: 0;
	}

	.nav-right ul li a {
		font-size: 1.6rem;
		font-weight: 700;
	}
}

.primary-nav {
	grid-area: primary-nav;
	order: 0;

	.header-logo {
		display: none;
	}

	.header-search-wrapper {
		align-items: center;
		display: flex;
		position: relative;
	}

	.header-search-wrapper input {
		background-color: $light-grey;
		border-bottom: 1px solid $dark-grey;
		border-left: none;
		border-right: none;
		border-top: 1px solid $dark-grey;
		height: 4.8rem;
		padding: 1.4rem 0 1.7rem 1.3rem;
		width: 100%;
	}

	.header-search-wrapper .search {
		background-color: transparent;
		border: none;
		color: $dark-grey;
		cursor: pointer;
		font-size: 1.8rem;
		height: 4.8rem;
		padding: 0 1.7rem;
		position: absolute;
		right: 0;
	}

	ul {
		margin: 0;
		padding: 0.55rem 1.7rem;
	}

	ul li {
		&.nav-search {
			display: none;
		}
	}

	ul li a {
		color: $middle-grey;
		display: block;
		font-size: 1.6rem;
		font-weight: 700;
		height: 100%;
		padding: 1.2rem 0;
		position: relative;
		text-transform: uppercase;

		&.main-nav-link.has-mega-menu {
			&:after {
				content: '\f054';
				font-family: $font-awesome;
				position: absolute;
				right: 0;
			}
		}

		&:hover, &:focus {
			text-decoration: none;
		}
	}

	.mega-menu {
		background-color: white;
		min-height: 100%;
		left: 0;
		padding: 1.8rem 1.7rem;
		position: absolute;
		top: 0;
		transform: translateX(-100%);
		transition: transform 0.2s linear;
		width: 100%;
		z-index: 200;

		&.open-mega-menu {
			transform: translateX(0);
		}

		a.nav-back-link {
			height: auto;
			padding: 0 0 3rem;

			&:before {
				content: '\f053';
				display: inline-block;
				font-family: $font-awesome;
				margin-right: 2.7rem;
			}
		}

		&-frame .subnav {
			&-header {
				text-align: left;
			}

			ul {
				padding: 0 0 2.8rem;
	
				li a:not(.subnav-header-stack) {
					font-weight: 300;
					padding: 1.2rem 0;
					text-transform: none;
				}
			}
		}
	}
}

.theme-inservco {
    .secondary-nav {
        .nav-right ul li a {
            font-weight: 500;
        }

        .desktop-tools {
            
            display: none;
        }
    }
}