.img-card-block {
    a {
        display: block;
        color: inherit;
        display: block;

        &:hover {
            color: inherit;
            text-decoration: none;

            span.link {
                text-decoration: underline;
            }
        }
    }

    .img-card-img {
        height: 256px;
        width: 100%;
        object-fit: cover;
    }

    .img-card-content {
        padding: 2.1rem 3.2rem 3.9rem;

        a {
            display: block;
            margin-top: 2.1rem;
            color: inherit;
            display: block;

            span {
                margin-left: 8px;
                color: $light-blue;
            }

            &:hover {
                color: $middle-blue;
            }
        }
    }

    .img-card.theme-dark {
        .img-card-content a {
            color: white;

            span {
                color: white;
            }

            &:hover {
                color: $light-blue;
            }
        }
    }
}
