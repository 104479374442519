.st-block {
	.container {
		margin: 0 auto;
		max-width: 105.6rem;
		padding: 5.33rem 4.8rem 11.2rem;
	}

	.quotation-mark {
		margin: 0 auto 4.77rem;
		width: 30px;
		height: 25px;
	}

	&-quote {
		font-size: 2.4rem;
		font-style: italic;
		font-weight: 500;
		letter-spacing: 0.48px;
		line-height: 1.33;
		margin: 0;

		&:before {
			content: '“';
		}

		&:after {
			content: '”';
		}

		& * {
			color: currentColor;
			font-family: inherit;
			font-style: inherit;
		}

		& a {
			text-decoration: underline;
		}
	}

	&-author {
		font-size: 2.2rem;
		font-weight: 700;
		letter-spacing: 1.65px;
		line-height: 1.27;
		margin: 3.2rem auto 0;
		text-transform: uppercase;
	}

	&-location {
		font-size: 1.6rem;
		letter-spacing: 0.16px;
		line-height: 1.75;
		margin: 0 auto;
	}

	.btn-block-wrapper {
		margin-top: 4.78rem;
	}

	&.theme-light {
		hr.divider {
			border-color: $black;
		}
	}

	&.theme-dark {
		background-color: $navy;
		color: white;
	}
}