.std-copy-block {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    width: 100%;

    h2 {
        display: inline-block;
    }

    p {
        margin-bottom: 0;
        margin-top: 2rem;

        &:first-child {
            margin-top: 0; 
        }
    }

    .btn-block-wrapper {
        margin-top: 2rem;
    } 

    hr {
        @extend .divider;
    }

    &.text-left {
        hr {
            margin: 2rem 0;
        }
    }

    &.text-right {
        hr {
            margin-right: 0;
        }
    }

    &.theme-dark {
        background-color: $navy;
        color: white;
    }
}

.theme-inservco {
    .std-copy-block, .std-copy-block-margins {
        .site-wrap {
            text-align: center;

            .btn-block-wrapper {
                justify-content: center;
            }
        }
    }
}