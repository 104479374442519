@import '../../Styles/variables';
@import '../../Styles/mixins';

.site-alert {
    color: white;
    font-size: 1.2rem;
    grid-area: site-alert;
    min-height: 4rem;
    margin-bottom: 40px;

    a {
        color: white;
        text-decoration: underline;
         
        &:hover {
            color: white;
        }
    }

    .site-wrap {
        padding: 1rem 1.7rem;

        :first-child {
            margin-top: 0;
        }

        :last-child {
            margin-bottom: 0;
        }
    }
}
.theme-inservco {
    .site-alert {
        margin-bottom: 0px;
    }
}