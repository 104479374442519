.search-wrapper {
    align-items: center;
    display: flex;
    position: relative;
}

.search-wrapper input {
    background-color: $light-grey;
    border: 1px solid $dark-grey;
    height: 4.8rem;
    padding: 1.4rem 0 1.7rem 1.3rem;
    width: 100%;
}

.search-wrapper .search {
    background-color: transparent;
    border: none;
    color: $dark-grey;
    cursor: pointer;
    font-size: 1.8rem;
    height: 4.8rem;
    padding: 0 1.7rem;
    position: absolute;
    right: 0;
}

.search-area {
    font-size: 1.8rem;
    font-weight: $font-weight-light;
    margin: 6rem auto;
    max-width: 105.6rem;
    padding-left: 1.7rem;
    padding-right: 1.7rem;
}

.search-result {
    word-wrap: break-word;
}

.pagination {
    &-block,
    &-numbers-container {
        display: flex;
        flex-flow: row;
        justify-content: center;

        :not(:last-child) {
            margin-right: 10px;
        }

        .pagination-number {
            &.active {
                font-weight: 700;
            }

            &:hover {
                text-decoration: none;
            }
        }

        .arrow {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25px;

            &-disabled {
                pointer-events: none;
                cursor: default;
                color: $middle-grey;
                opacity: 0.4;
            }

            &:hover {
                text-decoration: none;
            }
        }
    }

    ul {
        display: flex;
        justify-content: center;
        list-style: none;
        margin: 3.2rem 1.7rem;
        padding: 0;
    }
}