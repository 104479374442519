.accordion-block {
	&.full-width .accordion {
		padding-left: 1.7rem;
		padding-right: 1.7rem;
	}

	.accordion-item {
		background-color: transparent;
	}

	&.theme-dark .accordion-item {
		border-bottom-color: white;

		.accordion-button {
			color: white;

			&:hover,
			&[aria-expanded="true"]:hover {
				color: $light-blue;
			}

			&[aria-expanded="true"] {
				color: white;
				border-bottom-color: white;
			}
		}

		.accordion-body a {
			color: white;
			text-decoration: underline;
		}
	}

	&.theme-dark {
		background-color: $navy;
		color: white;
	}

	.accordion-item {
		border: none;
		border-bottom: 1px solid rgba($border-color, 0.3);
	}

	.accordion-button {
		background-color: transparent;
		border: none;
		box-shadow: none;
		color: $navy;
		cursor: pointer;
		font-size: 2.4rem;
		font-weight: $font-weight-bold;
		padding: 2.2rem 0;
		transition: color 0.2s linear;

		a {
			color: $navy;

			&:hover {
				text-decoration: none;
			}
		}

		&:hover {
			color: $middle-blue;
		}

		&[aria-expanded="true"] {
			color: $middle-blue;

			&::after {
				content: '\f068';
			}
		}

		&::after {
			content: '\f067';
			background-image: none;
			font-family: $font-awesome;
			font-size: 2.4rem;
			font-weight: $font-weight-normal;
			height: auto;
			width: auto;
		}
	}

	.accordion-body {
		font-size: 1.8rem;
		margin-top: 0;
		padding: 0 0 2.2rem;

		p {
			margin-top: 0;
		}
	}
}

.theme-inservco {
    .accordion-block {
        .accordion-button {
            &[aria-expanded="true"] {
                color: $middle-grey;
            }
        }

        &.theme-dark .accordion-item {
            .accordion-button {
                color: white;
            }
        }
    }
}