.pni-form {
    background-color: $light-grey;

    form .grid.has-separator {
        .form-separator {
            color: $border-color;
            display: none;
        }
    }

    .account-msg {
        border: 1px solid $border-color;
        font-size: 1.2rem;
        padding: 2.1rem 2.4rem;
    }

    .step-nav {
        grid-gap: 0.6rem;
        padding-bottom: 4.63rem;

        .step {
            border-bottom: 4px solid $border-color;
            color: $border-color;
            font-size: 1.6rem;
            font-weight: $font-weight-bold;
            padding-bottom: .68rem;
            text-align: center;
            text-transform: uppercase;

            &.current {
                border-color: $navy;
                color: $navy;
            }
        }

        .step:not(.current) {
            display: none;
        }
    }

    &-container {
        margin: 0 auto;
        max-width: 105.6rem;
        padding: 0 1.7rem;
    }

    .btn-wrapper {
        display: flex;
        flex-wrap: wrap;

        .btn {
            margin: 0 6.4rem 1rem 0;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .form-error {
        border: 4px solid $burgundy;
    }

    .error-msg {
        align-self: center;
        color: $burgundy;
        font-weight: 700;
        font-size: 1.4rem;
    }

    .success-msg {
        align-self: center;
        color: $light-blue;
        font-weight: 700;
        font-size: 1.4rem;
    }

    .important-alert {
        margin-top: 6.4rem;

        p {
            font-size: 1.6rem;
        }

        a {
            color: white;
            font-weight: 700;
            text-decoration: underline;
        }
    }

    .validation-summary-errors {
        ul {
            padding:0;

            li {
                display: block;
                font-size: 1.6rem;
                font-weight: 700;
                color: #792039;
            }
        }
    }

    .options-btn {
        margin-bottom: 2rem;
    }

    .make-a-payment-form,
    .paperless-options-form {
        .btn-wrapper > * {
            margin: 0 6.4rem 0 0;
        }
    }

    .payment-header-banner {
        background-color: $burgundy;
        color: $white;
        margin-top: 1em;
        padding: 1em;

        a, a:visited, a:hover, a:active {
            color: $white;
            font-weight: bold;
            text-decoration: underline;
        }
    }

    .signup-oic-form {
        margin-bottom: 6.4rem;

        &__result {
            padding: 2.4rem 0;
        }
    }
}
