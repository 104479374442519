.fw-hero-block {
	min-height: 39rem;
	overflow: hidden;
	position: relative;
	color: $dark-grey;

	&-img {
		display: block;
		min-height: 39rem;
		object-fit: cover;
		object-position: center;
		width: 100%;
	}

	&-content {
		background-color: rgba(255, 255, 255, 0.9);
		left: 50%;
		padding: 1.7rem;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 100%;

		&.no-bg {
			background-color: transparent;
		}

		.fw-hero-block-title {
			margin-bottom: 2.4rem;
		}
	}

	&.theme-dark {
		background-color: $navy;
		color: white;

		.fw-hero-block-content {
			background-color: $navy;
		}
	}
}
