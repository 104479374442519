.link-block {
    &-list {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            border-bottom: 1px solid $border-color;
            padding: 2rem 0;

            a {
                color: $navy;

                &:hover {
                    color: $middle-blue;
                    text-decoration: none;
                }
            }
        }
    }

    &.theme-dark {
        background-color: $navy;
        color: white;

        .link-block-list li {
            border-bottom-color: white;

            a {
                color: white;

                &:hover {
                    color: $light-blue;
                }
            }
        }
    }
}